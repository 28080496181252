.staking-card {
    padding: 15px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
}

.staking-card > button {
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
    color: #ffffff;
    border: none;
    padding: 0.5rem 0.625rem;
    border-radius: 0.375rem;
    cursor: pointer;
}

.staking-card > span  {
    font-weight: 700;
}